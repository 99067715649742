<template>
  <div class="d-flex align-center" :class="`background-auth${$vuetify.theme.dark ? '-dark' : ''}`" style="height: 100vh;">
    <v-card class="mx-auto text-left pa-8 bgPanel" :width="480" flat align="center" justify="center">
      <v-col class="text-center pa-0 mt-2 mb-6">
        <img :src="require(`@/assets/appBrand--ax-full${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" width="211">
        <span class="mt-2 d-block subtitle-1 fontBody--text font-weight-bold">Restablecer contraseña</span>
        <span class="mt-2 d-block body-1 fontBody--text">Escribe tu dirección de correo electrónico y te <br> enviaremos un vínculo para restablecer tu contraseña.</span>
      </v-col>
      <span class="text-body-2 text-fontBody">Email</span>
      <v-text-field v-model="email" @keyup.enter="resetPassword" :error="$v.email.$error" outlined hide-details required single-line dense />
      <v-col v-if="error" class="pa-0 text-center">
        <v-alert border="left" colored-border color="white" dense class="body-2 red--text pa-0">{{error}}</v-alert>
      </v-col>
      <v-btn class="mt-13 mb-2" @click="resetPassword" :loading="createLoader" color="primary" block>Enviar email</v-btn>
    </v-card>
  </div>
</template>
<script>
import { required, email, maxLength } from 'vuelidate/lib/validators'

export default {
  data: () => ({
    email: '',
    createLoader: false,
    error: null
  }),
  methods: {
    resetPassword () {
      this.$v.email.$touch()
      if (this.$v.email.$invalid) {
        return false
      }
      this.createLoader = true
      this.$store.dispatch('auth/PASSWORD_RESET', this.email)
      .then(() => {
        this.$dialog.message.info('Se ha enviado el email para reestablecer la contraseña.')
        this.$router.push({ name: 'AuthSignIn' })
      })
      .finally(() => {
        this.createLoader = false
      })
    }
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(254)
    }
  }
}
</script>